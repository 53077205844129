.App {
  font-family: "Tahoma", serif;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  overflow-x: hidden; /* Previeni scrolling orizzontale */
}

.App header {
  background: rgb(193,0,0,255);
  text-align: left;
  border-bottom: 2px solid rgb(78, 78, 78);
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0.50em;
  padding-bottom: 0.50em;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  background-color: #282c34;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.buttons1,
.buttons2 {
  margin: 0 1em;
}

.custom-button {
  color: white;
  border-color: white;
  margin-left: 1em;
  font-size: large;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
  border-radius: 12px; /* Angoli smussati */
}

.custom-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: white;
}

.buttons2 {
  margin-right: 2em; /* Margine destro sul bottone destro */
}

.help-button {
  color: #ffcc00; /* Colore distintivo per il bottone di Help */
  border-color: #ffcc00;
}

.help-button:hover {
  background-color: rgba(255, 204, 0, 0.1);
  border-color: #ffcc00;
}

.App .edit {
  margin: 0.5em auto;
  width: 95%;
}

.App .home {
  margin: 1em auto;
  width: 95%;
}

.App .import {
  margin: 1em auto;
  width: 95%;
}

.App .backgroundText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(0, 0, 0, 0.1); /* Colore nero con trasparenza */
  font-size: 3em; /* Regola la dimensione del testo secondo necessità */
  pointer-events: none; /* Assicurati che il testo non interferisca con l'input del TextField */
}
